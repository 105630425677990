import React from "react";

// Define inline styles
const containerStyle = {
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    maxWidth: '400px',
    marginTop: '10px'
};

const headingStyle = {
    fontSize: '1.5em',
    marginBottom: '10px'
};

const paragraphStyle = {
    fontSize: '1em',
    margin: '5px 0'
};

const negativeStyle = {
    color: 'red'
};

const positiveStyle = {
    color: 'green'
};
    

const TradeStats = ({ data , config }) => {
    console.log("stats", data);
    console.log("config",config)
    

    // Sort the data by date (assuming data includes a 'date' field)
    const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));

    let positiveStreak = 0;
    let negativeStreak = 0;
    let maxPositiveStreak = 0;
    let maxNegativeStreak = 0;
    let cumulativeProfit = 0;
    let maxProfit = 0;
    let drawdown = 0;
    let maxDrawdown = 0;
    let winAmount = 0;
    let maxWinAmount = 0;
    let winCount = 0;
    let totalTrades = 0;
    
    // Calculate stats for overall data
    sortedData.forEach(item => {
        cumulativeProfit += item.profit;
        maxProfit = Math.max(maxProfit, cumulativeProfit);
        drawdown = maxProfit - cumulativeProfit;
        maxDrawdown = Math.max(maxDrawdown, drawdown);
        if (item.profit > 0) {
            positiveStreak++;
            negativeStreak = 0;
            maxPositiveStreak = Math.max(maxPositiveStreak, positiveStreak);
            winAmount += item.profit;
            maxWinAmount = Math.max(maxWinAmount, winAmount);
            winCount++;
        } else {
            negativeStreak++;
            positiveStreak = 0;
            maxNegativeStreak = Math.max(maxNegativeStreak, negativeStreak);
            winAmount = 0;
        }
        totalTrades++;
    });

    // Calculate winrate as a percentage
    // console.log("totalTrades from stats",totalTrades)
    // console.log("winCount from stats",winCount)
    const winrate = totalTrades > 0 ? (winCount / totalTrades) * 100 : 0;

    // Calculate cumulative profit and winrate for each strategy
    const strategies = {};
    sortedData.forEach(item => {
        if (!strategies[item.strategy]) {
            strategies[item.strategy] = {
                cumulativeProfit: 0,
                winCount: 0,
                totalTrades: 0
            };
        }

        strategies[item.strategy].cumulativeProfit += item.profit;
        if (item.profit > 0) {
            strategies[item.strategy].winCount++;
        }
        strategies[item.strategy].totalTrades++;
    });

    // Generate strategy-specific stats
    const strategyStats = Object.keys(strategies).map(strategy => {
        const { cumulativeProfit, winCount, totalTrades } = strategies[strategy];
        const strategyWinRate = totalTrades > 0 ? (winCount / totalTrades) * 100 : 0;
        return (
            <div key={strategy} style={{ marginBottom: '10px' }}>
                <hr/>
                <p><strong>Strategy:</strong> {strategy}</p>
                <p>Cumulative Profit: <span style={cumulativeProfit >= 0 ? positiveStyle : negativeStyle}>${cumulativeProfit.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}</span></p>
                <p>Winrate (%): {strategyWinRate.toFixed(2)}</p>
            </div>
        );
    });


    return (
        <div style={containerStyle}>
            <h3 style={headingStyle}>Trade Statistics</h3>

            <p style={paragraphStyle}>
            Slippage from entry price: {config.inputValueComms*100}%
            </p>
            <p style={paragraphStyle}>
            Roundtrip Comms (ECN incl): {config.roundtripComm} per/share
            </p>
            <p style={paragraphStyle}>
            Locate Avg % from entry price: {config.locate_avg_perc_from_ep*100}%
            </p>

            <hr/>
            <p style={paragraphStyle}>
                Cumulative Profit: <span style={cumulativeProfit >= 0 ? positiveStyle : negativeStyle}>${cumulativeProfit.toLocaleString()}</span>
            </p>
            <hr/>
            <p style={paragraphStyle}>
                Max Positive Streak: <span style={positiveStyle}>{maxPositiveStreak}</span>
            </p>
            <p style={paragraphStyle}>
                Max Negative Streak: <span style={negativeStyle}>{maxNegativeStreak}</span>
            </p>
            <p style={paragraphStyle}>
                Max Drawdown: <span style={negativeStyle}>${maxDrawdown.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}</span>
            </p>
            <p style={paragraphStyle}>
                Max Cumulative Win Amount: <span style={positiveStyle}>${maxWinAmount.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}</span>
            </p>
            <p style={paragraphStyle}>
                Winrate (%): {winrate.toFixed(2)}
            </p>

            {/* Render strategy-specific stats */}
            <hr/>
            <h4>Strategy-Specific Stats</h4>
            {strategyStats}
            <hr/>
        </div>
    );
};

export default TradeStats;
