import React, { useState, useEffect } from 'react';

const Filters = ({ onApplyFilters, onSendFilters, columns }) => {
  const [filters, setFilters] = useState([{column: "", operator: "", value: "", logic: "AND"}]);
  
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("filters"));
    if (storedData) {
      setFilters(storedData);
    }
  }, []);

  const handleFilterChange = (index, key, value) => {
    const updatedFilters = [...filters];
    
    // When switching to "between" operator for dates, create two separate filter entries
    if (key === "operator" && value === "between" && isDateColumn(updatedFilters[index].column)) {
      // Create the "from" filter
      updatedFilters[index] = {
        column: updatedFilters[index].column,
        operator: ">=",
        value: "",
        logic: "AND",
        isDateRange: true
      };
      
      // Add the "to" filter right after
      updatedFilters.splice(index + 1, 0, {
        column: updatedFilters[index].column,
        operator: "<=",
        value: "",
        logic: "AND",
        isDateRange: true,
        isRangeEnd: true
      });
    } else if (key === "operator" && value !== "between") {
      // If switching away from "between", remove the second date filter if it exists
      if (updatedFilters[index].isDateRange) {
        updatedFilters.splice(index + 1, 1);
        updatedFilters[index] = {
          ...updatedFilters[index],
          operator: value,
          isDateRange: false
        };
      } else {
        updatedFilters[index][key] = value;
      }
    } else {
      updatedFilters[index][key] = value;
    }
    
    setFilters(updatedFilters);
    localStorage.setItem('filters', JSON.stringify(updatedFilters));
  };

  const handleDateRangeChange = (index, value) => {
    const updatedFilters = [...filters];
    if (updatedFilters[index].isDateRange) {
      // Update the "from" date
      updatedFilters[index].value = value;
      if (updatedFilters[index + 1]?.isRangeEnd) {
        // Keep the linked filters together when moving
        const rangeEnd = updatedFilters[index + 1];
        updatedFilters.splice(index + 1, 1);
        updatedFilters.splice(index + 1, 0, rangeEnd);
      }
    }
    setFilters(updatedFilters);
    localStorage.setItem('filters', JSON.stringify(updatedFilters));
  };

  const handleDateRangeEndChange = (index, value) => {
    const updatedFilters = [...filters];
    updatedFilters[index].value = value;
    setFilters(updatedFilters);
    localStorage.setItem('filters', JSON.stringify(updatedFilters));
  };

  const isDateColumn = (column) => {
    return column === "release_date" || column === "date";
  };

  const toggleFilterDisabled = (index) => {
    const updatedFilters = [...filters];
    if (updatedFilters[index].isDateRange) {
      // Toggle both date range filters together
      updatedFilters[index].disabled = !updatedFilters[index].disabled;
      if (updatedFilters[index + 1]?.isRangeEnd) {
        updatedFilters[index + 1].disabled = updatedFilters[index].disabled;
      }
    } else {
      updatedFilters[index].disabled = !updatedFilters[index].disabled;
    }
    setFilters(updatedFilters);
    saveToLocalStorage(updatedFilters);
    onSendFilters();
  };

  const saveToLocalStorage = (updatedFilters) => {
    const activeFilters = updatedFilters.filter((filter) => !filter.disabled);
    localStorage.setItem('filters', JSON.stringify(activeFilters));
  };

  const addFilter = () => {
    setFilters([...filters, { column: "", operator: "", value: "", logic: "AND" }]);
  };

  const deleteFilter = (index) => {
    const updatedFilters = [...filters];
    if (updatedFilters[index].isDateRange) {
      // Remove both date range filters
      updatedFilters.splice(index, 2);
    } else {
      updatedFilters.splice(index, 1);
    }
    setFilters(updatedFilters);
    localStorage.setItem('filters', JSON.stringify(updatedFilters));
  };

  useEffect(() => {
    if (onApplyFilters) {
      onApplyFilters(filters);
    }
  }, [onApplyFilters, filters]);

  const numericOperators = ["<", "<=", "=", ">=", ">"];
  const textOperators = ["contains", "contains not", "="];
  const dateOperators = ["<", "<=", "=", ">=", ">", "between"];

  return (
    <div className="filters-container">
      <div className="filters-list">
        {filters.map((filter, index) => {
          // Skip rendering the second part of a date range as it's handled with the first part
          if (filter.isRangeEnd) return null;

          return (
            <div key={index} className="filter-item">
              {index > 0 && (
                <select
                  value={filter.logic}
                  onChange={(e) => handleFilterChange(index, "logic", e.target.value)}
                  disabled={filter.disabled}
                >
                  <option value="AND">AND</option>
                  <option value="OR">OR</option>
                </select>
              )}

              <select
                value={filter.column}
                onChange={(e) => handleFilterChange(index, "column", e.target.value)}
                disabled={filter.disabled}
              > 
                <option value="" key={0}>Select Column</option>
                {columns?.map((header, index) => (
                  <option key={index+1} value={header}>{header}</option>
                ))}          
              </select>

              <select
                value={filter.isDateRange ? "between" : filter.operator}
                onChange={(e) => handleFilterChange(index, "operator", e.target.value)}
                disabled={!filter.column || filter.disabled}
              >
                <option value="">Select Operator</option>
                {(isDateColumn(filter.column)
                  ? dateOperators
                  : filter.column === "id" || filter.column === "vote_count" || filter.column === "runtime" || filter.column === "gap" || filter.column === "pnl" || filter.column === "ep"
                    ? numericOperators
                    : textOperators
                ).map((op) => (
                  <option key={op} value={op}>
                    {op}
                  </option>
                ))}
              </select>

              {filter.isDateRange ? (
                <div className="date-range-inputs">
                  <input
                    type="date"
                    value={filter.value}
                    onChange={(e) => handleDateRangeChange(index, e.target.value)}
                    disabled={filter.disabled}
                    placeholder="From date"
                  />
                  <span className="date-range-separator">to</span>
                  <input
                    type="date"
                    value={filters[index + 1]?.value || ""}
                    onChange={(e) => handleDateRangeEndChange(index + 1, e.target.value)}
                    disabled={filter.disabled}
                    placeholder="To date"
                  />
                </div>
              ) : isDateColumn(filter.column) ? (
                <input
                  type="date"
                  value={filter.value}
                  onChange={(e) => handleFilterChange(index, "value", e.target.value)}
                  disabled={!filter.column || !filter.operator || filter.disabled}
                />
              ) : (
                <input
                  type={filter.column === "vote_average" || filter.column === "popularity" || filter.column === "id" || filter.column === "runtime" ? "number" : "text"}
                  value={filter.value}
                  onChange={(e) => handleFilterChange(index, "value", e.target.value)}
                  disabled={!filter.column || !filter.operator || filter.disabled}
                />
              )}

              <button onClick={() => deleteFilter(index)}>Delete</button>
              <button onClick={() => toggleFilterDisabled(index)} className={filter.disabled ? "Enable" : "Disable"}>
                {filter.disabled ? "Enable" : "Disable"}
              </button>
            </div>
          );
        })}

        <button onClick={addFilter}>Add Filter</button>
        <button className="send-filters" onClick={onSendFilters}>Send Filters</button>
      </div>

      <style jsx>{`
        .date-range-inputs {
          display: flex;
          align-items: center;
          gap: 8px;
        }
        .date-range-separator {
          margin: 0 4px;
        }
      `}</style>
    </div>
  );
};

export default Filters;