import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Papa from 'papaparse';
import "../App.css";
import App from "../App"
import Swal from 'sweetalert2';
import Filters from "../components/Filters"
import Pnlchart from "../components/Chart"
import TradeCalendar from '../components/Calendar';
import TradeStats from "./Stats"
import { useDispatch, useSelector } from 'react-redux';
import { setTransformedData } from '../actions';

const headers = ['id', 'date', 'ticker', 'gap', 'pnl', 'pnlUsd', 'locate', 'rountrip', 'time', 'strategy', 'sig', 'ep', "pt", "sl", 'notes']

const roundtripComm = 0.005
const locate_avg_perc_from_ep = 0.01
const inputValueComms = 0.01
const accDepo = 30000

function Tradingbot() {
    const [dragging, setDragging] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'asc' });
    const [filteredData, setFilteredData] = useState([]);
    const [initialData, setInitialData] = useState([]);
    const [sqlQuery, setSqlQuery] = useState("");
    const [progress, setProgress] = useState(0);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [rowsCount, setRowsCount] = useState([]);
    const [inputValueUsd, setInputValue] = useState(localStorage.getItem("positionSize"))
    // const [inputValueComms, setInputValueComms] = useState(localStorage.getItem("commisionsPerc"))
    const [deposit, setDeposit] = useState(accDepo)
    const [posPercent, setposPercent] = useState(localStorage.getItem("positionPerc") || [])

    console.log(filteredData)
    //! make this in one function later
    const handleInputChangePosition = (event) => {
        setInputValue(event.target.value);

        const recalcPos = (event.target.value/deposit)*100
        setposPercent(recalcPos.toLocaleString());
        console.log(inputValueUsd)
    }

    // const handleInputChangeComms = (event) => {
    //     setInputValueComms(event.target.value); // Step 3: Update state on change
    //     console.log(inputValueComms)
    // }

    const handleInputChangePerc = (event) => {
        setposPercent(event.target.value);
        setInputValue((event.target.value*deposit)/100);
        console.log(`posPercent ${posPercent}`)
    }

    useEffect(() => {
        localStorage.setItem("positionSize", inputValueUsd);
        localStorage.setItem("commisionsPerc", inputValueComms);
        localStorage.setItem("positionPerc", posPercent);

    }, [inputValueUsd, inputValueComms,posPercent]);

    useEffect(() => {
        setFilteredData(initialData);
        console.log("refreshing data...", initialData)
    }, [initialData]);

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem("data") || "[]");
        if (storedData.length > 0 && initialData.length === 0) {
            console.log("why not setting from locastorage data", storedData)
            setFilteredData(storedData);
            console.log("filteredData", filteredData)
        }
    }, []);

    const convertToCSV = (data) => {
        const header = Object.keys(data[0]);
        const rows = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        rows.unshift(header.join(','));
        return rows.join('\r\n');
    };

    // Optional: Replacer function to handle special characters
    const replacer = (key, value) => value === null ? '' : value;

    // Function to handle the CSV download
    const downloadCSV = (data, filename) => {
        const csv = convertToCSV(data);
        const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');

        if (link.download !== undefined) { // feature detection
            const url = URL.createObjectURL(csvData);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };


    const handleDownload = () => {
        downloadCSV(filteredData, 'data.csv');
    };

    const fetchUsers = () => {
        axios.get('http://localhost:3500/api')
            .then(response => {
                setInitialData(response.data);
                setRowsCount(response.data.length);
            })
            .catch(error => {
                console.error('There was an error fetching the users!', error);
            });
    };

    const uploadData = () => {
        axios.post('http://localhost:3500/upload', {
            sqlQuery,
        })
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.error('There was an error uploading the data!', error);
            });
    };

    const delRow = async (id) => {
        // Set actionStatus and wait for state to update before proceeding

        const response = await axios.post("http://localhost:3500/delete", {
            data: id
        })

        console.log(`attempting to delete row id ${id} with status code ${response.status}`)

        if (response.status === 201 || response.status === 200) {
            Swal.fire({
                icon: 'success',
                title: 'Row Deleted!',
                text: `Row id ${id} has been deleted`,
                showConfirmButton: false,
                timer: 2000,
            });

            sendFilters()

        } else {
            Swal.fire({
                icon: 'error',
                title: 'error!',
                text: `failed with status code ${response.status}`,
                showConfirmButton: false,
                timer: 2000,
            });
        }
    };


    const sendFilters = () => {
        const localStorageFilters = JSON.parse(localStorage.filters)
        console.log(`sending localstorage filters: ${localStorageFilters}`)
        // console.log(`sending appliedFilters filters: ${appliedFilters}`)
        axios.post('http://localhost:3500/filters', {
            filters: localStorageFilters
        })
            .then(response => {
                console.log(response.data);
                setInitialData(response.data);
                setRowsCount(response.data.length);
                localStorage.setItem('data', JSON.stringify(response.data));
            })
            .catch(error => {
                console.error('There was an error uploading the data!', error);
            });


    };

    const handleDrop = (event) => {
        event.preventDefault();
        setDragging(false);

        const files = event.dataTransfer.files;
        if (files.length === 0) return;

        const file = files[0];
        if (file.type === 'application/json') {
            handleJsonFile(file);
            console.log("uploading file...")
        } else if (file.type === 'text/csv') {
            handleCsvFile(file);
        } else {
            alert('Please upload a CSV or JSON file.');
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragging(true);
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type === 'application/json') {
                handleJsonFile(file);
            } else if (file.type === 'text/csv') {
                handleCsvFile(file);
            } else {
                alert('Please upload a CSV or JSON file.');
            }
        }
    };

    const handleJsonFile = (file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            try {
                const jsonData = JSON.parse(event.target.result);
                setInitialData(jsonData);
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        };
        reader.readAsText(file);
    };

    const handleCsvFile = (file) => {
        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            onUploadProgress: (progressEvent) => {
                const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percent);
                console.log(percent)
                console.log("test")

            },
            complete: (result) => {
                setInitialData(result.data);
            },

            error: (error) => {
                console.error('Error parsing CSV:', error);
            }
        });
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    //! somethinbg wrong here sorting not always correct
    useEffect(() => {  //renders on any changes in dependency 
        let sortedData = [...transformedData];
        sortedData.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
        setFilteredData(sortedData);
    }, [sortConfig]); //renders on any changes in dependency 

    const handleCheckboxChange = (checked) => {
        console.log("checked passed", checked)
        checked = !checked
        console.log("checked", checked)
    }

    // const [modalIsOpen, setModalIsOpen] = useState(false);
    // const [actionStatus, setActionStatus] = useState(false);

    // const openModal = () => {
    //   return new Promise((resolve, reject) => {
    //     try {
    //       setModalIsOpen(true);

    //       resolve("Promise resolved!"); // Resolve after actions are done
    //     } catch (error) {
    //       console.error('There was an error performing the action!', error);
    //       reject(error); // Reject in case of error
    //     }
    //   });
    // };


    // useEffect(() => {
    //   // Example use of openModal
    //   openModal().then(message => console.log(message));
    // }, []); // Empty dependency array ensures this runs once on mount


    // const closeModal = () => setModalIsOpen(null);

    // const handleConfirm = () => {
    //   setActionStatus(true)
    //   console.log("actionStatusfrom handleConfirm", actionStatus);
    //   // Handle the confirmation action here
    //   closeModal();
    //   return true
    // };

    const [pageActive, setpageActive] = useState(false);
    const [tradeCalendarActive, settradeCalendarActive] = useState(false);


    const pageActiveHandler = () => {
        setpageActive(!pageActive)
        console.log("pageActive", pageActive)
    }


    const [editRow, setEditRow] = useState(null);
    const [editRowId, setEditRowId] = useState(null);

    const handleEditClick = (rowIndex, id) => {
        setEditRow(rowIndex);
        console.log("setting row id", id)
        setEditRowId(id)
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setEditRow(null);
            } else if (event.key === 'Enter') {
                console.log("changing row id", editRowId)
                handleSaveClick(editRowId); //! state issue row always at null here
            }
        };

        // Add the event listener
        window.addEventListener('keydown', handleKeyDown);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []); // Ensure dependencies are added if needed

    const addNewRow = () => {
        const newRow = {
            id: null,  // Use a timestamp or some unique ID for the new row
            date: new Date().toISOString().split('T')[0],  // Default to today's date
            ticker: '',
            gap: 0,
            pnl: 0,
            time: '',
            strategy: '',
            sig: '',
            ep: 0,
            pt: 0,
            sl: 0,
            notes: 'manual insert',
            sma200: "0"
        };

        setFilteredData((prevData) => {
            const updatedData = [newRow, ...prevData];
            return updatedData;
        });

        // Set edit mode on the newly added row
        setEditRow(0);  // Set the index to edit the last row (newly added)
        setEditRowId(newRow.id);  // Set the id for the newly added row
    };


    const handleSaveClick = (id) => {
        setEditRow(null);
        filteredData.map((item) => {
            if (item.id === id) {
                // Assuming you want to replace the item with some new values
                const newValues = item; // You might want to transform or use newValues differently
                console.log(`sending values to db for update ${newValues}`)

                axios.post('http://localhost:3500/update', {
                    data: newValues
                })
                    .then(response => {
                        console.log(response.data);
                        Swal.fire({
                            icon: 'success',
                            title: 'Row Updated!',
                            text: `${response.data.info}`,
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    })
                    .catch(error => {
                        console.error('There was an error uploading the data!', error);
                    });

                return newValues;
            }
            return item; // Return the original item if it doesn’t match the id
        });

    };
    const handleChange = (e, rowIndex, field, id) => {
        const { value } = e.target;
        const updatedData = [...filteredData];
        updatedData[rowIndex] = { ...updatedData[rowIndex], [field]: value };
        setFilteredData(updatedData);

        console.log(`row has been updated with value ${id}`)
    };

    const [showChart, setshowChart] = useState(false);

    const mappedData = filteredData.map(function (item) {
        const ep = item.ep === 0 ? 1 : item.ep; // Check if ep is 0 and set it to 1
        const usd_pnl = item.pnl * inputValueUsd

        const shares = inputValueUsd / ep
        const roundtrip_comms = roundtripComm * shares
        const borrows = (ep * locate_avg_perc_from_ep) * shares;
        const slippage = inputValueUsd * inputValueComms

        const comms = borrows + roundtrip_comms

        return {
            date: item.date.split('T')[0],
            pnl: usd_pnl - comms // PNL minus roundtripComm cents comms roundtrip * shares amount for 1k $,
            //& calc total roundtrip comms for all
        };
    });


    const result = mappedData.reduce((acc, item, index) => {
        const pnlAdjusted = item.pnl - Math.abs(inputValueUsd * inputValueComms);
        const cumulatedPnl = index === 0 ? pnlAdjusted : acc.pnl_cum[index - 1] + pnlAdjusted;

        acc.dates.push(item.date);
        acc.pnl.push(pnlAdjusted);
        acc.pnl_cum.push(cumulatedPnl);

        return acc;
    }, { dates: [], pnl: [], pnl_cum: [] });
    console.log("result", result)


    // data array with applied comms for all data
    const tradeData = filteredData.map(function (item) {
        const ep = item.ep === 0 ? 1 : item.ep; // Check if ep is 0 and set it to 1
        const usd_pnl = item.pnl * inputValueUsd
        const shares = inputValueUsd / ep
        const roundtrip_comms = roundtripComm * shares
        const borrows = (ep * locate_avg_perc_from_ep) * shares;
        const slippage = inputValueUsd * inputValueComms
        const strategy = item.strategy
        return {
            date: item.date.split('T')[0],
            profit: usd_pnl - (roundtrip_comms + borrows + slippage),
            commisions: roundtrip_comms + borrows,
            shares: shares,
            strategy:strategy
        };
    });

    const dispatch = useDispatch();
    const transformedData = useSelector((state) => state.transformedData);

    useEffect(() => {
        const newData = filteredData.map((item) => {
            const ep = item.ep === 0 ? 1 : item.ep; // Check if ep is 0 and set it to 1
            const usd_pnl = item.pnl * inputValueUsd; // Calculate usd_pnl
            const shares = inputValueUsd / ep; // Calculate shares
            const roundtrip_comms = roundtripComm * shares; // Calculate roundtrip commissions
            const borrows = (ep * locate_avg_perc_from_ep) * shares; // Calculate borrows and slippage
            const slippage = inputValueUsd * inputValueComms

            return {
                ...item, // Spread the original item to keep all existing data
                pnlUsd: (usd_pnl - (roundtrip_comms + borrows + slippage)).toFixed(2),
                locate: borrows.toFixed(2), //! change to be 1.5% from entry price
                rountrip: roundtrip_comms.toFixed(2),
            };
        });

        dispatch(setTransformedData(newData));
    }, [filteredData, inputValueUsd, inputValueComms, dispatch]);



    if (pageActive) {
        return (
            <App />
        )
    } else {
        return (
            <div className="App">

                <nav style={{
                    border: "1px solid #ccc",
                    float: "right",
                    padding: "10px 20px",
                    backgroundColor: "#f8f9fa",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    display: "flex",
                    gap: "15px",
                    alignItems: "center",
                    marginBottom: "10px"
                }}>
                    <button onClick={pageActiveHandler}>Back</button>
                    <button onClick={null}>OS-Db</button>
                    <button onClick={handleDownload}>Download CSV</button>
                    <button onClick={() => setshowChart(!showChart)}>Chart</button>
                    <button onClick={() => settradeCalendarActive(!tradeCalendarActive)}>Calendar</button>
                    <input type='number' step="500" placeholder={"$ per trade"} value={inputValueUsd} onChange={handleInputChangePosition} ></input>
                    {/* <input type='number' step="0.005" placeholder={"% of comms, etc 10%"} value={inputValueComms} onChange={handleInputChangeComms} ></input> */}
                    <input type='number' step="1" placeholder={"position % from acc"} value={posPercent} onChange={handleInputChangePerc} ></input>
                    <input type='text' readOnly style={{border:"none",cursor: "not-allowed"}} placeholder={"deposit"} value={`Acc: ${deposit.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`}></input>
                    {/* <label>{Math.round(inputValueUsd * inputValueComms)}$ comms per trade</label> */}
                </nav>

                <h1>TRADING BOT</h1>

                {tradeCalendarActive ? <TradeCalendar
                    tradeData={tradeData}
                    deposit={deposit}
                    alltrades={transformedData}
                />
                    : null}


                {/* {
                showChart ? null :
                    <div className={`drag-drop-area ${dragging ? 'dragging' : ''}`}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                        onDragLeave={() => setDragging(false)}
                    >
                        <p>Drag and drop a CSV or JSON file here, or click to upload</p>
                        <input type="file" onChange={handleFileInputChange} />
                    </div>
                } */}

                <Filters
                    onApplyFilters={(filters) => setAppliedFilters(filters)} // Update filters when applied
                    onSendFilters={sendFilters}
                    columns={headers}
                />

                {
                    showChart ? null :
                        <div className="sql-query">
                            <textarea
                                value={sqlQuery}
                                onChange={(e) => setSqlQuery(e.target.value)}
                                placeholder="Write your SQL query here"
                            />
                        </div>
                }

                {showChart ?
                    <div className='strategyChart'>
                        <Pnlchart
                            data={result}
                            usdPosition={inputValueUsd}
                            borrowsEtcPerc={inputValueComms}
                        />
                    </div>
                    : null}


                <div className="table-container">
                    <button className="upload-data" onClick={uploadData}>Send Query</button>
                    <button className="fetch-data" onClick={fetchUsers}>Fetch ALL</button>
                    <progress value={progress} max="100" />
                    <label>  Count {rowsCount}</label>
                    <TradeStats
                        data={tradeData}
                        config={{locate_avg_perc_from_ep,roundtripComm,inputValueComms}}
                    />

                    <nav className='tableNav' style={{
                        border: "1px solid #ccc",
                        padding: "10px 20px",
                        backgroundColor: "#f8f9fa",
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        display: "flex",
                        gap: "15px",
                        alignItems: "center",
                        marginTop:"10px"
                    }}>
                        {<button style={{ float: "right" }} onClick={addNewRow}>add new row</button>}
                    </nav>
                    <table>
                        <thead>
                            <tr>
                                {headers.map((header) => (
                                    <th onClick={() => handleSort(header)}>{header}</th>
                                ))}
                                <th>Edit</th>
                                <th>Del</th>
                            </tr>
                        </thead>
                        <tbody>

                            {transformedData.map((item, index) => (
                                <tr key={index}>
                                    {headers.map((header) => (
                                        <td
                                            style={header === "pnl" ? (item.pnl < 0 ? { color: "red", fontWeight: "bold" } : { color: "green", fontWeight: "bold" }) : null}
                                            key={`${item.id}-${header}`}
                                        >

                                            {editRow === index && header !== "id" ? (
                                                <input
                                                    type="text"
                                                    value={item[header]}
                                                    onChange={(e) => handleChange(e, index, header, item.id)}
                                                />
                                            ) : (
                                                header === "pnl" ? `${(item[header] * 100).toFixed(2)}%` : item[header]
                                            )}
                                        </td>

                                    ))}
                                    <td>{editRow === index ? (
                                        <button onClick={(() => handleSaveClick(item.id))}>Save</button>
                                    ) : (
                                        <button onClick={() => handleEditClick(index, item.id)}>Edit</button>
                                    )}</td>
                                    <td><button className='del-btn' onClick={() => delRow(item.id)} >Del</button></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default Tradingbot;
