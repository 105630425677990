import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './TradeCalendar.css'; // Import the custom CSS file

const TradeCalendar = ({ tradeData, deposit, alltrades }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showDetails, setShowDetails] = useState(false);
  const [tradeDetails, setTradeDetails] = useState({});
  const [tradeSummary, setTradeSummary] = useState({});
  const [view, setView] = useState('month'); // Set initial view to "month"
  const [activeStartDate, setActiveStartDate] = useState(new Date()); // Track active start date

  const calculateMonthlyProfitAndWinRate = () => {
    // Helper function to strip time from date
    const stripTime = (date) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    };

    // Initialize start and end of month (time will be 00:00:00)
    const startOfMonth = new Date(activeStartDate.getFullYear(), activeStartDate.getMonth(), 1);
    const endOfMonth = new Date(activeStartDate.getFullYear(), activeStartDate.getMonth() + 1, 0);

    // Strip time from boundary dates
    const startOfMonthNoTime = stripTime(startOfMonth);
    const endOfMonthNoTime = stripTime(endOfMonth);

    let totalProfit = 0;
    let totalWins = 0;
    let totalTrades = 0;

    for (const [date, { profit, trades }] of Object.entries(tradeSummary)) {
        // Create date object and strip time
        const currentDate = stripTime(new Date(date));

        if (currentDate >= startOfMonthNoTime && currentDate <= endOfMonthNoTime) {
            // console.log("startOfMonth", startOfMonthNoTime, "endOfMonth", endOfMonthNoTime, "currentDate", currentDate);
            totalProfit += parseFloat(profit);
            totalTrades += trades;
        }
        
    }

   
  
    for (const [key, trade] of Object.entries(alltrades)) {
      const { date, pnlUsd } = trade;  // Extract the 'date' and 'pnl' from the object
      const currentDate = new Date(date);
  
      if (isNaN(currentDate)) {
        console.error(`Invalid date encountered: ${date}`);
        continue; // Skip this iteration if the date is invalid
      }
      // // Format the date as YYYY-MM-DD
      // const formattedDate = currentDate.toISOString().split('T')[0];  // Extracts 'YYYY-MM-DD' from ISO format
    
      // console.log("Formatted Date:", formattedDate);
      // Ensure the currentDate is within the desired range
      if (currentDate >= startOfMonth && currentDate <= endOfMonth) {
        if (pnlUsd > 0) {
          totalWins += 1;
        }
      }
    }
    // console.log("totalWins",totalWins)
    // console.log("totalTrades",totalTrades)
  
    const winRate = totalTrades > 0 ? (totalWins / totalTrades).toLocaleString('de-DE', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) : '0%';
  
    return { totalProfit: totalProfit.toFixed(2), winRate , totalTrades };
  };

  // Check if the day is a workday (Monday to Friday)
  const isWorkday = (date) => {
    const day = date.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
    return day !== 0 && day !== 6; // Workdays are Monday to Friday (1-5)
  };

  // Determine the CSS class for profit based on its value
  const getProfitClass = (profit) => {
    return profit < 0 ? 'profit-negative' : 'profit-positive';
  };

  // Check if a date is in the currently viewed month
  // const isCurrentMonth = (date) => {
  //   const startOfMonth = new Date(activeStartDate.getFullYear(), activeStartDate.getMonth(), 1);
  //   const endOfMonth = new Date(activeStartDate.getFullYear(), activeStartDate.getMonth() + 1, 0);
  //   return date >= startOfMonth && date <= endOfMonth;
  // };

  const isSunday = (date) => {
    return date.getDay() === 0;
  };

  const getWeekKey = (date) => {
    const weekNumber = getISOWeekNumber(date);
    const year = date.getFullYear();
    return `${year}-W${weekNumber}`;
  };

  const getWeeklyProfit = (date) => {
    const weekKey = getWeekKey(date);
    return weeklyProfits[weekKey] || null;
  };


  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      const formattedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString().split('T')[0];
      const tradeInfo = tradeSummary[formattedDate] || { trades: 0, profit: '0.00' };
      const dayClass = isWorkday(date) ? 'workday' : 'weekend';


      // Render weekly profit on Sundays
      if (isSunday(date)) {
        return (
          <div className={`tile-box ${dayClass}`}>
            <div className="tile-header">{date.getDate()}</div>
            <div className="tile-body">
              <span className={`weekly-profit ${getWeeklyProfit(date) < 0 ? "profit-negative" : getWeeklyProfit(date) > 0 ? "profit-positive" : null}`}>
                {getWeeklyProfit(date) ? `Week Profit: ${getWeeklyProfit(date)} | ${(getWeeklyProfit(date)/deposit).toLocaleString('de-DE', {
                    style: 'percent',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}` : null}
              </span>
            </div>
          </div>
        );
      }

      return (
        <div className={`tile-box ${dayClass} ${tradeInfo.profit < 0 ? 'tile-box-red' : tradeInfo.profit > 0 ? 'tile-box-green' : ''}`}>
          <div className="tile-header">{date.getDate()}</div>
          <div className="tile-body">
            <span className="trade-info">{tradeInfo.trades === 1 ? `${tradeInfo.trades} trade` : tradeInfo.trades > 1 ? `${tradeInfo.trades} trades` : ''}</span>
            {tradeInfo.trades > 0 && (
              <>
                <span style={{ "marginTop": "10px" }} className={`profit-info ${getProfitClass(Number(tradeInfo.profit))}`}>
                  Profit: ${tradeInfo.profit}
                </span>
                <span style={{ "marginTop": "2px" }} className={`profit-info ${getProfitClass(Number(tradeInfo.profit))}`}>
                  {(tradeInfo.profit / deposit).toLocaleString('de-DE', {
                    style: 'percent',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              </>
            )}
          </div>
        </div>
      );
    }
  };

  const [weeklyProfits, setWeeklyProfits] = useState({});
  useEffect(() => {
    const calculateTradeSummary = () => {
      const summary = tradeData.reduce((acc, { date, profit, commisions, shares }) => {
        if (!acc[date]) {
          acc[date] = { trades: 0, profit: 0, comms: 0, shares: 0 };
        }
        acc[date].trades += 1; // Count the number of trades
        acc[date].profit += profit;
        acc[date].comms += commisions;
        acc[date].shares += shares;
        
        return acc;
      }, {});
  
      // Round the profit values to 2 decimal places
      for (const date in summary) {
        summary[date].profit = summary[date].profit.toFixed(2);
      }
  
      setTradeSummary(summary);
    };
  
    calculateTradeSummary();
  }, [tradeData]);
  
  // Separate useEffect for calculating weekly profits after tradeSummary updates
  useEffect(() => {
    const calculateWeeklyProfits = () => {
      const weeks = {};
      Object.keys(tradeSummary).forEach(dateStr => {
        let date = new Date(dateStr);
        date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        const weekNumber = getISOWeekNumber(date);
        const year = date.getFullYear();
        const key = `${year}-W${weekNumber}`;
  
        if (!weeks[key]) {
          weeks[key] = 0;
        }
        weeks[key] += parseFloat(tradeSummary[dateStr].profit);
      });
  
      // Round weekly profits to 2 decimal places
      for (const week in weeks) {
        weeks[week] = weeks[week].toFixed(2);
      }
  
      setWeeklyProfits(weeks);
    };
  
    if (Object.keys(tradeSummary).length > 0) {
      calculateWeeklyProfits();
    }
  }, [tradeSummary]); // Only recalculate weekly profits when tradeSummary changes
  


  // Helper function to get ISO week number
  const getISOWeekNumber = (date) => {
    const tempDate = new Date(date.getTime()); // Copy the date object to avoid mutating the original
    tempDate.setUTCDate(tempDate.getUTCDate() + 4 - (tempDate.getUTCDay() || 7)); // Adjust to the nearest Thursday
    const jan4 = new Date(Date.UTC(tempDate.getUTCFullYear(), 0, 4)); // January 4th is always in week 1
    const weekNumber = Math.ceil((((tempDate - jan4) / 86400000) + 1) / 7);
    return weekNumber;
  };


  // Handle tile click event
  const handleTileClick = (date) => {
    const formattedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const tradeInfo = tradeSummary[formattedDate.toISOString().split('T')[0]] || { trades: 0, profit: '0.00' };
    setTradeDetails(tradeInfo);
    setSelectedDate(formattedDate); // Set date with UTC to avoid timezone shift
    setShowDetails(true);
  };


  // Function to go back to the current date
  const handleBackToNow = () => {
    setSelectedDate(new Date());
    setView('month'); // Reset the view to month when going back to now
  };

  // Function to handle view change (month/year)
  const handleViewChange = ({ activeStartDate }) => {
    setActiveStartDate(activeStartDate); // Update active start date based on calendar's active start date
    setView('month'); // Ensure view is set to month when date changes
  };

  const { totalProfit, winRate , totalTrades } = calculateMonthlyProfitAndWinRate();
  console.log(totalProfit, winRate , totalTrades)
  const perc_from_acc_value = (totalProfit / deposit).toLocaleString('de-DE', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });


  return (
    <div className="calendar-container">
      <div className="monthly-profit">
        <button className="back-to-now-btn" onClick={handleBackToNow}>
          Back to Now
        </button>

        <h3>
          Total Profit for {activeStartDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
          <label className={`profit-info ${totalProfit < 0 ? 'profit-negative' : 'profit-positive'}`}>
            {`___$${totalProfit} | ${perc_from_acc_value} from Deposit | Win Rate: ${winRate} | Trades: ${totalTrades}`}
          </label>
        </h3>
      </div>

      <Calendar
        onChange={setSelectedDate}
        value={selectedDate}
        tileContent={tileContent}
        onClickDay={handleTileClick}
        className="custom-calendar"
        view={view}
        onActiveStartDateChange={handleViewChange}
        next2Label={null}
        prev2Label={null}
        onClickMonth={() => setView('month')}
        onClickYear={() => setView('year')}
      />

      {showDetails && (
        <div className="details-popup">
          <h3>Details for {selectedDate.toDateString()}</h3>
          <p>Trades: {tradeDetails.trades}</p>
          {tradeDetails.trades > 0 && (
            <p className={`profit-info ${getProfitClass(Number(tradeDetails.profit))}`}>
              Cumulative Profit: ${tradeDetails.profit}
            </p>
          )}
          <button onClick={() => setShowDetails(false)}>Close</button>
        </div>
      )}
    </div>
  );


};

export default TradeCalendar;
