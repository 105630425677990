import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Papa from 'papaparse';
// import ConfirmActionModal from "./components/Modal"
import "./App.css";
import Swal from 'sweetalert2';
import Filters from "./components/Filters"
import Tradingbot from "./components/CrudTradingbot"
import CalorieCounter from "./components/Macros"




const headers = ["id", "adult", "genres", "vote_count", "vote_average", "title", "popularity", "spoken_languages", "keywords", "overview", "release_date", "runtime","notes"]

function App() {
  const [dragging, setDragging] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'asc' });
  const [filteredData, setFilteredData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [sqlQuery, setSqlQuery] = useState("");
  const [progress, setProgress] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [rowsCount, setRowsCount] = useState([]);

  useEffect(() => {
    setFilteredData(initialData);
    console.log("refreshing data...", initialData)
  }, [initialData]);

  const fetchUsers = () => {
    axios.get('http://localhost:3500/api')
      .then(response => {
        setInitialData(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the users!', error);
      });
  };

  const uploadData = () => {
    axios.post('http://localhost:3500/upload', {
      sqlQuery,
    })
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error('There was an error uploading the data!', error);
      });
  };

  const delRow = async (id) => {
    // Set actionStatus and wait for state to update before proceeding

    const response = await axios.post("http://localhost:3500/delete", {
      data: id
    })

    console.log(`attempting to delete row id ${id} with status code ${response.status}`)

    if (response.status === 201) {
      Swal.fire({
        icon: 'success',
        title: 'Row Deleted!',
        text: `Row id ${id} has been deleted`,
        showConfirmButton: false,
        timer: 2000,
      });

      sendFilters()

    } else {
      Swal.fire({
        icon: 'error',
        title: 'error!',
        text: `failed with status code ${response.status}`,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };


  const sendFilters = () => {
    const localStorageFilters = JSON.parse(localStorage.filters)
    console.log(`sending localstorage filters: ${localStorageFilters}`)
    // console.log(`sending appliedFilters filters: ${appliedFilters}`)
    axios.post('http://localhost:3500/filters', {
      filters: localStorageFilters
    })
      .then(response => {
        console.log(response.data);
        setInitialData(response.data);
        setRowsCount(response.data.length);
      })
      .catch(error => {
        console.error('There was an error uploading the data!', error);
      });
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);

    const files = event.dataTransfer.files;
    if (files.length === 0) return;

    const file = files[0];
    if (file.type === 'application/json') {
      handleJsonFile(file);
      console.log("uploading file...")
    } else if (file.type === 'text/csv') {
      handleCsvFile(file);
    } else {
      alert('Please upload a CSV or JSON file.');
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === 'application/json') {
        handleJsonFile(file);
      } else if (file.type === 'text/csv') {
        handleCsvFile(file);
      } else {
        alert('Please upload a CSV or JSON file.');
      }
    }
  };

  const handleJsonFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const jsonData = JSON.parse(event.target.result);
        setInitialData(jsonData);
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    };
    reader.readAsText(file);
  };

  const handleCsvFile = (file) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      onUploadProgress: (progressEvent) => {
        const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProgress(percent);
        console.log(percent)
        console.log("test")

      },
      complete: (result) => {
        setInitialData(result.data);
      },

      error: (error) => {
        console.error('Error parsing CSV:', error);
      }
    });
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  //! issue in state here forever loop render
  useEffect(() => {  //renders on any changes in dependency 
    let sortedData = [...filteredData];
    sortedData.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
    setFilteredData(sortedData);
  }, [sortConfig]); //renders on any changes in dependency 

  const handleCheckboxChange = (checked) => {
    console.log("checked passed", checked)
    checked = !checked
    console.log("checked", checked)
  }

  // const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [actionStatus, setActionStatus] = useState(false);

  // const openModal = () => {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       setModalIsOpen(true);

  //       resolve("Promise resolved!"); // Resolve after actions are done
  //     } catch (error) {
  //       console.error('There was an error performing the action!', error);
  //       reject(error); // Reject in case of error
  //     }
  //   });
  // };


  // useEffect(() => {
  //   // Example use of openModal
  //   openModal().then(message => console.log(message));
  // }, []); // Empty dependency array ensures this runs once on mount


  // const closeModal = () => setModalIsOpen(null);

  // const handleConfirm = () => {
  //   setActionStatus(true)
  //   console.log("actionStatusfrom handleConfirm", actionStatus);
  //   // Handle the confirmation action here
  //   closeModal();
  //   return true
  // };

  const [pageActive, setpageActive] = useState(false);
  const [macros, setMacros] = useState(false);
  
  const pageActiveHandler = () => {
    setpageActive(!pageActive)
    console.log("pageActive",pageActive)
  }


  if (true){
    return (
      <Tradingbot
      />
    )
  }else{
  
  
  const macrosHandler= ()=> {
    setMacros(!macros)
    console.log("macros",macros)
  }
  
  if (macros){
    return(
    <CalorieCounter
    back={pageActiveHandler}
    />
    )
  }

  return (
    <div className="App">

      {/* <ConfirmActionModal
        isOpen={modalIsOpen}
        onClose={closeModal}
        onConfirm={handleConfirm}
      /> */}
      <nav style={{
        border: "1px solid #ccc",
        float: "right",
        padding: "10px 20px",
        backgroundColor: "#f8f9fa",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        display: "flex",
        gap: "15px",
        alignItems: "center"
      }}>
        <button onClick={macrosHandler}>Macros</button>
        <button onClick={pageActiveHandler}>Tradingbot CRUD</button>
      </nav>

      <h1>Movie DB</h1>
      <li>add column with watched and add filtering on watched</li>

      <div className={`drag-drop-area ${dragging ? 'dragging' : ''}`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragLeave={() => setDragging(false)}
      >
        <p>Drag and drop a CSV or JSON file here, or click to upload</p>
        <input type="file" onChange={handleFileInputChange} />
      </div>

      <Filters
        onApplyFilters={(filters) => setAppliedFilters(filters)} // Update filters when applied
        onSendFilters={sendFilters}
        columns={headers}
      />

      <div className="sql-query">
        <textarea
          value={sqlQuery}
          onChange={(e) => setSqlQuery(e.target.value)}
          placeholder="Write your SQL query here"
        />
      </div>

      <div className="table-container">
        <button className="upload-data" onClick={uploadData}>Send Query</button>
        <button className="fetch-data" onClick={fetchUsers}>Fetch ALL</button>
        <progress value={progress} max="100" />
        <label>  Count {rowsCount}</label>

        <table>
          <thead>
            <tr>
              <th onClick={() => handleSort('id')}>ID</th>
              <th onClick={() => handleSort('title')}>Title</th>
              <th onClick={() => handleSort('release_date')}>Release Date</th>
              <th onClick={() => handleSort('popularity')}>Popularity</th>
              <th onClick={() => handleSort('vote_count')}>Vote Count</th>
              <th onClick={() => handleSort('vote_average')}>Vote Average</th>
              <th onClick={() => handleSort('keywords')}>keywords</th>
              <th onClick={() => handleSort('overview')}>overview</th>
              <th onClick={() => handleSort('genres')}>genres</th>
              <th onClick={() => handleSort('adult')}>adult</th>
              <th onClick={() => handleSort('spoken_languages')}>languages</th>
              <th onClick={() => handleSort('runtime')}>runtime</th>
              <th>DEL</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item,index) => (
              <tr key={index}>

                <td>{item.id}</td>
                <td>{item.title}</td>
                {/* <EditableCell value={filteredData.title}/> */}
                <td>{item.release_date}</td>
                <td>{item.popularity}</td>
                <td>{item.vote_count}</td>
                <td>{item.vote_average}</td>
                <td>{item.keywords}</td>
                <td>{item.overview}</td>
                <td>{item.genres}</td>
                <td>
                  <label>
                    <input
                      type="checkbox"
                      checked={item.adult}
                      onChange={() => handleCheckboxChange(item.adult)}
                    />
                  </label>
                </td>
                <td>{item.spoken_languages}</td>
                <td>{item.runtime}</td>
                <td onClick={() => delRow(item.id)} className='del-btn'>DEL</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
}

export default App;
