import { SET_TRANSFORMED_DATA } from './actions';

const initialState = {
    transformedData: [],
};

const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TRANSFORMED_DATA:
            return {
                ...state,
                transformedData: action.payload,
            };
        default:
            return state;
    }
};

export default dataReducer;
